.buttons {
  display: flex;
  justify-content: left;
  padding-left: 20%;
  margin: 20px;
}

.button {
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border: none;
  background: none;
  font-size: 16px;
  font-weight: bold;
  color: black;
  outline: none;
}


.button.selected {
  color: #000000;
  border-bottom: 3px solid rgba(15, 98, 254);
}

.content-display {
  margin-top: 20px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
}