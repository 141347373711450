.header-slider {
    position: relative;
    width: 100%;
}

.header-slider img {
    width: 100%;
    object-fit: cover; /* 裁切图片 */
    display: block; /* 去除图片下方的空隙 */
    margin: 0; /* 确保没有默认外边距 */
    padding: 0; /* 确保没有默认内边距 */
}

.header-slider.full img {
    height: 75vh; /* 全屏高度 */
}

.header-slider.half img {
    height: 50vh; /* 半屏高度 */
}

.header-slider.quarter img {
    height: 25vh; /* 四分之一屏高度 */
}

.caption {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(255, 255, 255, 1);
}

.caption h1 {
    text-shadow: 2px 2px 8px rgba(0,0,0,0.6); /* 为标题添加阴影效果以提高可读性 */
}

.caption p {
    margin: 20px 0;
    text-shadow: 1px 1px 4px rgba(0,0,0,0.6); 
}
