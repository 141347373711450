

.outer-container {
    padding-top: 5%;
    padding-bottom: 12%;
    padding-left: 15%;
    padding-right: 15%;
    position: relative;
}



h2 ,h3 ,h4 ,h5 {
    font-weight: 100;
    font-size: 1.7vw;
}

h1 {
    font-weight: 100;
    font-size: 2.2vw;
}

p {
    font-weight: 200;
    padding: 0.1vw;
    font-size: 1.2vw;
}

.text-link {
    font-weight: 200;
    padding: 2px;
    font-size: 1vw;
    color: rgba(15, 98, 254);
}

body {
    width: 100%;
    overflow: auto; /* 允许滚动 */
    margin: 0 auto; /* 居中显示 */
    background-color: rgba(250, 250, 250, 0.50);
}

@media screen and (max-width: 1200px) {
    body {
        width: 1200px;
    }
    
    .outer-container {
        width: 100%;
        padding-left: 0%;
        padding-right: 0%;
        position: relative;
    }

    h2 ,h3 ,h4 ,h5 {
        font-weight: 100;
        font-size: 35px;
    }
    
    h1 {
        font-weight: 100;
        font-size: 45px;
    }
    
    p {
        font-weight: 200;
        padding: 2px;
        font-size: 25px;
    }
    
    .text-link {
        font-weight: 200;
        padding: 2px;
        font-size: 20px;
        color: rgba(15, 98, 254);
    }
}
