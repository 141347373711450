.row-container {
    display: flex;  /* 这将使子元素横向排列 */
    align-items: center; /* 垂直居中对齐 */
    justify-content: space-between; /* 水平居中对齐 */

}


.row-container.image-left .text-image-block {
    order: 1; /* 图片在左 */
}

.row-container.image-left .content {
    order: 2; /* 文字在右 */
    padding-left: 8%;
}

.row-container.image-right .text-image-block {
    order: 2; /* 图片在右 */
}

.row-container.image-right .content {
    order: 1; /* 文字在左 */
    padding-right: 8%;
}

.row-container.row-container.content p {
    text-align: justify;
}

.row-container.row-container.image-left .content  {
    text-align: justify;

}

.row-container.image-right .content  {
    text-align: justify;
}

.text-image-block img {
    height: 27vw; 
    width: 20vw; 
    object-fit: cover; /*裁切图片*/
}

.sub-title {
    font-size: 3vw; /* 3% of 1920px */
}

.text-with-image-container.with-bottom {
    border-bottom: 1px solid #ccc;
    padding-bottom: 5%; /* 没有改变，因为百分比是基于容器的高度或宽度 */
}

@media screen and (max-width: 1200px) {
    .row-container {
        padding-left: 5%;
        padding-right: 5%;
    }

    .text-image-block img {
        height: 540px; 
        width: 400px; 
    }

    .sub-title {
        font-size: 60px; /* 3% of 1920px */
    }
}
