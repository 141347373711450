.footer {
    position: relative;
    background-color: #2e3e4f;
    color: white;
	padding: 40px 0;
}

.footer a {
    color: white;
    text-decoration: none;
}

.container {
    display: flex;
}

.contact-us, .attention-us, .more-links {
    flex: 1;
    margin: 20px;
    text-align: center;
}

.attention-us-content,.more-links-content {
    flex: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.attention-us-content a,.more-links-content a {
    margin: 10px;
    width: 120px;
    height: 100px;
}

.footer-bottom {
    text-align: center;
}