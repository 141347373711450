#all-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#title-line {
    display: flex;
    justify-content: space-between;
}

.main-title {
    text-align: left;
    font-size: 1.2vw;
    margin-bottom: 1vw;
}
  
.items {
    display: flex;
    justify-content: space-between;
}

.item-first {
    position: relative;
    width: 28vw;
    height: 18vw;/*是否不等宽*/
}
  
.other-items {
    position: relative;
    width: 15vw;
    height: 18vw;
}
  
.item-first img {
    width: 28vw;
    height: 18vw;/*是否不等宽*/
    object-fit: cover; /*裁切图片*/
}

.other-items img {
    width: 15vw;
    height: 18vw;
    object-fit: cover; /*裁切图片*/
}
  
.white-more {
    display: flex;
    flex-direction: column;
    justify-content: center;
	left:10%;
	top:10%;
    right: 10%;
    text-decoration: none;
    font-size: 25px;
    color: black;
}

.introduce-white {
    position: absolute;
    color: white;
	left:10%;
	top:40%;
    right: 10%;
}

.introduce-white-under {
    color: black;
    margin-right: 0%;
}

.whitebackground-introduce {
    font-weight: 200;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-left: 10%;
}

.white-more:hover {
    color: rgba(0, 0, 0, 0.618);
}

@media (max-width: 1200px) {

    .main-title {
        text-align: left;
        font-size: 24px;
        margin-bottom: 20px;
    }

    .items {
      flex-direction: column;
      align-items: center;
    }
  
    .item-first, .other-items {
        width: 768px;
        height: 768px;/*是否不等宽*/
        margin-bottom: 20px; /* Optional: add spacing between items */
    }
  
    .item-first img, .other-items img {
        width: 768px;
        height: 512px;/*是否不等宽*/
        object-fit: cover; /*裁切图片*/
    }
  
    .introduce-white-under {
      width: 90%; /* Adjust width for better appearance */
      margin-top: 20px;
    }

    .introduce-white h2 ,.introduce-white-under h2{
        font-size: 35px;
    }
    
    .introduce-white p ,.introduce-white-under p{
        font-size: 30px;
    }
    
    .whitebackground-introduce {
        font-size: 30px;
        font-weight: 200;
    }
}