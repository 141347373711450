.development-timeline {
    text-align: center;
    font-family: Arial, sans-serif;
  }
  
  .timeline {
    display: flex;
    justify-content: center;
    padding: 10px;
    background-color: #f0f0f0;
    margin: 20px 0;
  }
  
  .timeline-point {
    padding: 10px;
    margin: 0 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .timeline-point:hover {
    background-color: #e0e0e0;
  }
  
  .timeline-point.selected {
    background-color: #d0d0d0;
    border-color: #999;
  }
  
  .event-details {
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  